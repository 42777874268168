// Logout
.modal-logout {

    @include custom-size-modal($width: 25rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                p {

                    font-size: 1.1rem;
                    font-weight: 400;
                }
            }
        }

    }
}


// Delete data in table
.modal-delete-date-table {

    @include custom-size-modal($width: 35rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                .content-body {
                    
                    height: 6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p{

                        font-size: 1.1rem;
                        font-weight: 400;
                    }
                }
            }
        }

    }
}