@import "../scss/base";
@import "../scss/mixin";
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");



* {

    margin: 0rem;
    padding: 0rem;
    box-sizing: border-box;
}

// consfiguration grid 
.grid-gap-custom-normal,
.grid-gap-custom-small,
.grid-custom-one-column,
.grid-custom-two-column,
.grid-custom-three-column,
.grid-custom-four-column,
.grid-custom-five-column,
.grid-custom-six-column,
.grid-custom-two-one-column,
.grid-custom-one-two-column {

    display: grid;
}


.grid-gap-custom-small {

    gap: 0.5rem;
}

.grid-gap-custom-normal {

    gap: 0.5rem;
}

.grid-gap-customv1 {

    gap: .5rem;
}


.grid-custom-one-column {

    grid-template-columns: 1fr;
}


.grid-custom-two-2one-column {

    grid-template-columns: 2fr repeat(2, 1fr);
}

.grid-custom-two-one-column {

    grid-template-columns: 2fr 1fr;
}

.grid-custom-one-two-column {

    grid-template-columns: 1fr 2fr;
}


.grid-custom-two-column {

    grid-template-columns: repeat(2, 1fr);
}


.grid-custom-three-column {

    grid-template-columns: repeat(3, 1fr);
}


.grid-custom-four-column {

    grid-template-columns: repeat(4, 1fr);
}


.grid-custom-five-column {

    grid-template-columns: repeat(5, 1fr);
}

p,
span,
small,
div,
label,
input,
button {

    font-size: 1rem;
    color: $gray-normal;
    margin-bottom: 0rem;
}

a,
.link-btn-custom {

    text-decoration: none;
}

.modal-form-custom-all {

    .card-custom {

        position: relative;
        background-color: $white;
        @include custom-border-radius($radius: 1rem);

        .close-icon {

            color: $error;
            position: absolute;
            right: 1.5rem;
            top: 1rem;
            z-index: 3;
            font-size: 1.5rem;
        }

        .content {

            padding: 1.5rem;
        }
    }
}