@mixin custom-border-radius($radius: 1rem) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin custom-transition() {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

@mixin custom-transfort($x: -50%, $y: -50%) {
    transform: translate($x, $y);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


@mixin custom-bar-scrool($height: 0.2rem, $width: 0.2rem) {

    overflow-y: auto;

    &::-webkit-scrollbar {

        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: rgba(89, 89, 89, 0.5);
        @include custom-border-radius($radius: 0.4);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(89, 89, 89, 0.2);
        border-radius: 20px;
    }
}


@mixin custom-size-modal-react-bootstrap($width: 100%, $height: auto, $widthModalContent: 100%) {

    .modal-dialog {
        width: $width !important;
        // max-width: none;
        --bs-modal-width: auto;

        .modal-content {

            width: $widthModalContent !important;
            height: $height;
        }
    }
}

@mixin custom-size-modal($width: 100%, $height: auto, $widthModalContent: 100%) {

    .modal-dialog {
        width: $width !important;
        // max-width: none;
        --bs-modal-width: auto;

        .modal-content {

            width: $widthModalContent !important;
            height: $height;
        }
    }
}