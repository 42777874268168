@import "../../colors";
@import "../../breakpoints";
@import "../../mixin";

#login {

    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: $white;

    .content {

        width: 30rem;
        padding: 2rem 2.5rem;
        background-color: $background-light;
        position: absolute;
        top: 50%;
        left: 50%;
        @include custom-transfort($x: -50%, $y: -50%);
        @include custom-border-radius($radius: 1rem);
        box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);

        img {

            background-color: $white;
            @include custom-border-radius($radius: 1rem);
            margin-bottom: 2rem;
            box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
        }

        .btn-secondary,
        .btn-desable {

            margin-top: 3rem;
        }

        form {

            div {

                &.password-field-custom {

                    position: relative;

                    .icon-disploy-hidden {

                        position: absolute;
                        display: flex;
                        justify-items: center;
                        align-items: center;
                        top: 3.25rem;
                        right: 3%;
                        @include custom-transfort($x: -50%, $y: -50%);
                        cursor: pointer;
                        @include custom-transition();

                        .icon {

                            color: $gray-normal;
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }
    }
}