@import './colors';
@import './sidebar-navbar';
@import './forms';
@import './mixin';
@import './modal';
@import './tables';
@import './apps/auths/login';
@import './menu-navbar-hv';
@import './apps/admins/admin';



h4 {

    &.title {

        font-size: 1.3rem;
        font-weight: 500;
        color: $gray-bold;
        width: fit-content;

        &::after {

            content: '';
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: $primary;
        }
    }
}

.cusom-go-back-page {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-top: 1.5rem;
}


#admin-account {


    .custom-display-info {

        gap: 1rem;

        .loading-for-display-data {

            display: flex;
            justify-content: center;
            align-items: center;

        }

        .item-display {

            display: grid;
            grid-template-columns: 12rem 1fr;
        }
    }

    .custom-display-change-password {

        margin-top: 2rem;

        form {

            .item-form {

                display: grid;
                grid-template-columns: 1fr;
            }

            .custom-btn-submit-position {

                display: flex;
                justify-content: flex-end;

                .btn-custom-secondary {

                    width: fit-content;
                }
            }
        }
    }
}

.copyright {

    position: absolute;
    bottom: 1rem;
    right: 5rem;

    p {

        font-weight: 600;
    }
}


// ------------------------------------------------------------------------------
// ------------------------- List table display data ----------------------------
// ------------------------------------------------------------------------------

#admin-settings-security,
#admin-users,
#admin-benediciare-bases,
#admin-customers-base,
#admin-dashboard,
#admin-expenses,
#admin-benediciare-bases,
#admin-providers-services-venders,
#admin-settings-accesManagemnts-privileges,
#admin-settings-accesManagemnts-roles,
#admin-settings-events-typeOfSurprise,
#admin-settings-geography-countries,
#admin-settings-geography-localities,
#admin-settings-mediaType,
#admin-settings-payments,
#admin-settings-producttType-BeneficiaryLinkType,
#admin-settings-producttType-TypeOfInvestment,
#admin-settings-security {

    .custom-btn-header {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 1rem;

        .link-btn-custom {

            button {

                width: fit-content;
            }
        }
    }
}