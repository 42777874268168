$white: #ffffff;
$green-color: #00b809;
$primary : #065F89;
$secondary : #218DAA;
$thirth : #64B4CD;
$fouth : #C6DFE6;
$background-light : rgba(198, 223, 230, 0.1);
$error : #d70000;
$gray-very-light : rgba(231, 231, 231, 1);
$gray-light : #b0b0b0;
$gray-normal : #6d6d6d;
$gray-bold : #454545;
$desable-background: $fouth;
$desable-color: $thirth;


// primary #065F89
// secondary #218DAA
// thirth #C6DFE6
// fouth #64B4CD