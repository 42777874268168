
ul {

    margin: 0;
    padding: 0;

    &.custom-navbar-nav-bottom-light{

        display: grid;
        // background-color: red;
        grid-template-columns: repeat(7, 1fr);
        @include custom-border-radius($radius: 0.8rem);
        position: relative;

        &::after {

            content: "";
            display: block;
            width: 100%;
            height: 0.05rem;
            background-color: $primary;
            opacity: 0.4;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        li {

            list-style-type: none;

            &.nav-item {

                .nav-link {

                    padding: 0.5rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0.05rem solid transparent;
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;

                    &:hover:not(&.active) {

                        background-color: $white;
                        color: $primary;
                        border-color: $primary;
                    }

                    &.active {

                        position: relative;
                        color: $primary;

                        &::after {

                            content: "";
                            display: block;
                            width: 100%;
                            height: 0.3rem;
                            position: absolute;
                            bottom: -0.16rem;
                            z-index: 2;
                            left: 0rem;
                            background-color: $primary;
                            @include custom-border-radius($radius: 1rem);
                        }
                    }
                }
            }
        }
    }

    &.custom-navbar-nav-full {

        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
    
        margin: 0;
        padding: 0;
    
        .nav-item {
    
    
            &:first-child {
    
                .nav-link {
    
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                }
            }
     
            &:last-child {
    
                .nav-link {
    
                    border-top-right-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                }
            }
    
            .nav-link {
    
                text-decoration: none;
                padding: 0.4rem 0.6rem;
                background-color: $white;
                border: 0.05rem solid $primary;
    
                &.active {
    
                    color: $white;
                    background-color: $primary;
    
                }
            }
        }
    }
}


