@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
#field36-content-admin {
  background-color: rgba(198, 223, 230, 0.1);
  position: relative;
  display: flex;
}
#field36-content-admin #sidebar {
  position: fixed;
  width: 18rem;
  height: 100vh;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0.05rem 0rem 1.5rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #sidebar .sidebar-content .logo {
  display: flex;
  justify-content: center;
  margin: 0.2rem 0rem;
}
#field36-content-admin #sidebar .sidebar-content .logo img {
  height: 5rem;
  width: 5rem;
  background-color: white;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav {
  display: grid;
  grid-template-columns: 1fr;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link {
  color: #454545;
  padding: 0.6rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link:hover:not(#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active) {
  background-color: #ffffff;
  color: #065F89;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link:hover:not(#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active) .icon {
  color: #065F89;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link .icon {
  font-size: 1.3rem;
  color: #454545;
  margin-right: 1rem;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active {
  background-color: #065F89;
  color: #ffffff;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active .icon {
  color: #ffffff;
}
#field36-content-admin #navbar {
  width: 100vw;
}
#field36-content-admin #navbar .navbar {
  position: sticky;
  top: 0rem;
  width: 100%;
  z-index: 999;
  height: 5rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.05rem 1.5rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #navbar .navbar .content-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#field36-content-admin #navbar .navbar .content-navbar span {
  margin-left: 20rem;
}
#field36-content-admin #navbar .navbar .content-navbar .left {
  margin-right: 2rem;
}
#field36-content-admin #navbar .navbar .content-navbar .left span {
  margin-right: 1rem;
}
#field36-content-admin #navbar .full-content {
  width: 100%;
  padding-left: 18rem;
  padding-top: 0.5rem;
  height: calc(100% - 5rem);
  background-color: #ffffff;
}
#field36-content-admin #navbar .full-content .card-custom {
  height: calc(100vh - 7.5rem);
  margin: 0.5rem 1rem 1rem 1rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  box-shadow: 0.01rem 0.01rem 0.6rem rgba(0, 0, 0, 0.1);
}

.forms-all {
  margin: 1rem 5rem;
}

.errors-fields {
  color: #d70000;
  font-size: 0.8rem;
}

label {
  font-size: 1rem;
}

span.spinner-border {
  color: #065F89;
  display: block;
  margin-right: 0.5rem;
}

button.btn-custom-primary, button.btn-custom-desable, button.btn-custom-secondary, button.btn-custom-primary-icon, button.btn-custom-canceled, button.btn-custom-green-icon {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  width: 100%;
  padding: 0.4rem 1rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
button.btn-custom-canceled {
  background-color: #b0b0b0;
  border: 0.05rem solid #b0b0b0;
  color: #ffffff;
}
button.btn-custom-canceled:hover {
  background-color: #ffffff;
  color: #b0b0b0;
}
button.btn-custom-primary-icon {
  background-color: white;
  border: 0.05rem solid #065F89;
}
button.btn-custom-primary-icon span,
button.btn-custom-primary-icon .icon {
  display: block;
  color: #065F89;
  font-size: 0.8rem;
}
button.btn-custom-primary-icon span {
  margin-left: 0.3rem;
}
button.btn-custom-primary-icon .icon {
  margin-top: 0.1rem;
}
button.btn-custom-primary-icon:hover {
  background-color: #065F89;
  color: #ffffff;
  border-color: #065F89;
}
button.btn-custom-primary-icon:hover span,
button.btn-custom-primary-icon:hover .icon {
  color: #ffffff;
}
button.btn-custom-green-icon {
  background-color: white;
  border: 0.05rem solid #00b809;
}
button.btn-custom-green-icon span,
button.btn-custom-green-icon .icon {
  display: block;
  color: #00b809;
  font-size: 0.8rem;
}
button.btn-custom-green-icon span {
  margin-left: 0.3rem;
}
button.btn-custom-green-icon .icon {
  margin-top: 0.1rem;
}
button.btn-custom-green-icon:hover {
  background-color: #00b809;
  color: #ffffff;
  border-color: #00b809;
}
button.btn-custom-green-icon:hover span,
button.btn-custom-green-icon:hover .icon {
  color: #ffffff;
}
button.btn-custom-primary {
  background-color: white;
  border: 0.05rem solid #065F89;
  color: #065F89;
}
button.btn-custom-primary:hover {
  background-color: #065F89;
  color: #ffffff;
  border-color: #065F89;
}
button.btn-custom-desable {
  color: #64B4CD;
  background-color: #C6DFE6;
  border: 0.05rem solid #64B4CD;
}
button.btn-custom-secondary {
  background-color: #065F89;
  border: 0.05rem solid #065F89;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
button.btn-custom-secondary:hover {
  background-color: #ffffff;
  color: #065F89;
  border-color: #065F89;
}

input {
  font-size: 1rem;
  background-color: #ffffff;
}
input.form-control {
  background-color: #ffffff;
}
input.form-control:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
input.form-control:hover {
  border-color: #065F89;
}
input.form-check-input:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
input.form-check-input:hover {
  border-color: #065F89;
}
input[type=checkbox]:checked, input[type=radio]:checked {
  background-color: #065F89;
  border-color: #065F89;
}

.form-switch .form-check-input {
  height: 1.4rem;
  width: 2.5rem !important;
  cursor: pointer;
}

textarea.form-control:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
textarea.form-control:hover {
  border-color: #065F89;
}

select.form-select:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
select.form-select:hover {
  border-color: #065F89;
}
select.form-select option:hover {
  background-color: gray;
}

.custom-form {
  margin-top: 1.5rem;
}
.custom-form .grid-custom-two-column {
  display: flex;
  justify-content: end;
}
.custom-form .grid-custom-two-column .btn-custom-canceled,
.custom-form .grid-custom-two-column .btn-custom-secondary, .custom-form .grid-custom-two-column .btn-custom-desable {
  width: -moz-fit-content;
  width: fit-content;
}

.modal-logout .modal-dialog {
  width: 25rem !important;
  --bs-modal-width: auto;
}
.modal-logout .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-logout .card-custom .content.content-content p {
  font-size: 1.1rem;
  font-weight: 400;
}

.modal-delete-date-table .modal-dialog {
  width: 35rem !important;
  --bs-modal-width: auto;
}
.modal-delete-date-table .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-delete-date-table .card-custom .content.content-content .content-body {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-delete-date-table .card-custom .content.content-content .content-body p {
  font-size: 1.1rem;
  font-weight: 400;
}

.responsible-table-custom {
  overflow-y: auto;
  height: calc(100vh - 15rem);
}
.responsible-table-custom::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.2rem;
}
.responsible-table-custom::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: rgba(89, 89, 89, 0.5);
  border-radius: 0.4;
  -webkit-border-radius: 0.4;
  -moz-border-radius: 0.4;
  -ms-border-radius: 0.4;
  -o-border-radius: 0.4;
}
.responsible-table-custom::-webkit-scrollbar-track {
  background-color: rgba(89, 89, 89, 0.2);
  border-radius: 20px;
}
.responsible-table-custom table.table {
  border-bottom: 1px solid rgba(138, 138, 138, 0.3);
  border-right: 1px solid rgba(138, 138, 138, 0.3);
  border-left: 1px solid rgba(138, 138, 138, 0.3);
  margin-bottom: 0rem;
}
.responsible-table-custom table.table thead {
  position: sticky;
  top: 0rem;
}
.responsible-table-custom table.table thead tr {
  background-color: transparent;
}
.responsible-table-custom table.table thead tr th {
  background-color: #b0b0b0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}
.responsible-table-custom table.table tbody tr, .responsible-table-custom table.table tfoot tr {
  background-color: transparent;
  border-bottom: 0.05rem solid rgba(46, 46, 46, 0.2);
}
.responsible-table-custom table.table tbody tr:hover, .responsible-table-custom table.table tfoot tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: default;
}
.responsible-table-custom table.table tbody tr td, .responsible-table-custom table.table tfoot tr td {
  font-size: 0.8rem;
  background-color: transparent;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 0.8rem 0.4rem;
}
.responsible-table-custom table.table tbody tr td.custom-loading-data-td, .responsible-table-custom table.table tfoot tr td.custom-loading-data-td {
  display: none;
}
.responsible-table-custom table.table tbody tr td.td-action, .responsible-table-custom table.table tfoot tr td.td-action {
  gap: 1rem;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link, .responsible-table-custom table.table tfoot tr td.td-action .td-link {
  padding: 0.4rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link .icon, .responsible-table-custom table.table tfoot tr td.td-action .td-link .icon {
  font-size: 0.8rem;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.edit, .responsible-table-custom table.table tfoot tr td.td-action .td-link.edit {
  background-color: #ffffff;
  border: 0.05rem solid #00b809;
  color: #00b809;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.edit:hover, .responsible-table-custom table.table tfoot tr td.td-action .td-link.edit:hover {
  color: #ffffff;
  background-color: #00b809;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.delete, .responsible-table-custom table.table tfoot tr td.td-action .td-link.delete {
  background-color: #ffffff;
  border: 0.05rem solid #d70000;
  color: #d70000;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.delete:hover, .responsible-table-custom table.table tfoot tr td.td-action .td-link.delete:hover {
  color: #ffffff;
  background-color: #d70000;
}
.responsible-table-custom table.table tbody tr td img.img-td, .responsible-table-custom table.table tfoot tr td img.img-td {
  width: 2rem;
  height: 2rem;
}

.content-or-subcontent-settings .responsible-table-custom {
  height: calc(100vh - 20rem);
}

#login {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #ffffff;
}
#login .content {
  width: 30rem;
  padding: 2rem 2.5rem;
  background-color: rgba(198, 223, 230, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
}
#login .content img {
  background-color: #ffffff;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
}
#login .content .btn-secondary,
#login .content .btn-desable {
  margin-top: 3rem;
}
#login .content form div.password-field-custom {
  position: relative;
}
#login .content form div.password-field-custom .icon-disploy-hidden {
  position: absolute;
  display: flex;
  justify-items: center;
  align-items: center;
  top: 3.25rem;
  right: 3%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#login .content form div.password-field-custom .icon-disploy-hidden .icon {
  color: #6d6d6d;
  font-size: 1.3rem;
}

ul {
  margin: 0;
  padding: 0;
}
ul.custom-navbar-nav-bottom-light {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  position: relative;
}
ul.custom-navbar-nav-bottom-light::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.05rem;
  background-color: #065F89;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
}
ul.custom-navbar-nav-bottom-light li {
  list-style-type: none;
}
ul.custom-navbar-nav-bottom-light li.nav-item .nav-link {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05rem solid transparent;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
ul.custom-navbar-nav-bottom-light li.nav-item .nav-link:hover:not(ul.custom-navbar-nav-bottom-light li.nav-item .nav-link.active) {
  background-color: #ffffff;
  color: #065F89;
  border-color: #065F89;
}
ul.custom-navbar-nav-bottom-light li.nav-item .nav-link.active {
  position: relative;
  color: #065F89;
}
ul.custom-navbar-nav-bottom-light li.nav-item .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.3rem;
  position: absolute;
  bottom: -0.16rem;
  z-index: 2;
  left: 0rem;
  background-color: #065F89;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
ul.custom-navbar-nav-full {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
}
ul.custom-navbar-nav-full .nav-item:first-child .nav-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
ul.custom-navbar-nav-full .nav-item:last-child .nav-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
ul.custom-navbar-nav-full .nav-item .nav-link {
  text-decoration: none;
  padding: 0.4rem 0.6rem;
  background-color: #ffffff;
  border: 0.05rem solid #065F89;
}
ul.custom-navbar-nav-full .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #065F89;
}

.content-or-subcontent-settings {
  margin-top: 1rem;
}

h4.title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #454545;
  width: -moz-fit-content;
  width: fit-content;
}
h4.title::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: #065F89;
}

.cusom-go-back-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
}

#admin-account .custom-display-info {
  gap: 1rem;
}
#admin-account .custom-display-info .loading-for-display-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
#admin-account .custom-display-info .item-display {
  display: grid;
  grid-template-columns: 12rem 1fr;
}
#admin-account .custom-display-change-password {
  margin-top: 2rem;
}
#admin-account .custom-display-change-password form .item-form {
  display: grid;
  grid-template-columns: 1fr;
}
#admin-account .custom-display-change-password form .custom-btn-submit-position {
  display: flex;
  justify-content: flex-end;
}
#admin-account .custom-display-change-password form .custom-btn-submit-position .btn-custom-secondary {
  width: -moz-fit-content;
  width: fit-content;
}

.copyright {
  position: absolute;
  bottom: 1rem;
  right: 5rem;
}
.copyright p {
  font-weight: 600;
}

#admin-settings-security .custom-btn-header,
#admin-users .custom-btn-header,
#admin-benediciare-bases .custom-btn-header,
#admin-customers-base .custom-btn-header,
#admin-dashboard .custom-btn-header,
#admin-expenses .custom-btn-header,
#admin-benediciare-bases .custom-btn-header,
#admin-providers-services-venders .custom-btn-header,
#admin-settings-accesManagemnts-privileges .custom-btn-header,
#admin-settings-accesManagemnts-roles .custom-btn-header,
#admin-settings-events-typeOfSurprise .custom-btn-header,
#admin-settings-geography-countries .custom-btn-header,
#admin-settings-geography-localities .custom-btn-header,
#admin-settings-mediaType .custom-btn-header,
#admin-settings-payments .custom-btn-header,
#admin-settings-producttType-BeneficiaryLinkType .custom-btn-header,
#admin-settings-producttType-TypeOfInvestment .custom-btn-header,
#admin-settings-security .custom-btn-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
#admin-settings-security .custom-btn-header .link-btn-custom button,
#admin-users .custom-btn-header .link-btn-custom button,
#admin-benediciare-bases .custom-btn-header .link-btn-custom button,
#admin-customers-base .custom-btn-header .link-btn-custom button,
#admin-dashboard .custom-btn-header .link-btn-custom button,
#admin-expenses .custom-btn-header .link-btn-custom button,
#admin-benediciare-bases .custom-btn-header .link-btn-custom button,
#admin-providers-services-venders .custom-btn-header .link-btn-custom button,
#admin-settings-accesManagemnts-privileges .custom-btn-header .link-btn-custom button,
#admin-settings-accesManagemnts-roles .custom-btn-header .link-btn-custom button,
#admin-settings-events-typeOfSurprise .custom-btn-header .link-btn-custom button,
#admin-settings-geography-countries .custom-btn-header .link-btn-custom button,
#admin-settings-geography-localities .custom-btn-header .link-btn-custom button,
#admin-settings-mediaType .custom-btn-header .link-btn-custom button,
#admin-settings-payments .custom-btn-header .link-btn-custom button,
#admin-settings-producttType-BeneficiaryLinkType .custom-btn-header .link-btn-custom button,
#admin-settings-producttType-TypeOfInvestment .custom-btn-header .link-btn-custom button,
#admin-settings-security .custom-btn-header .link-btn-custom button {
  width: -moz-fit-content;
  width: fit-content;
}

* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}

.grid-gap-custom-normal,
.grid-gap-custom-small,
.grid-custom-one-column,
.grid-custom-two-column,
.grid-custom-three-column,
.grid-custom-four-column,
.grid-custom-five-column,
.grid-custom-six-column,
.grid-custom-two-one-column,
.grid-custom-one-two-column {
  display: grid;
}

.grid-gap-custom-small {
  gap: 0.5rem;
}

.grid-gap-custom-normal {
  gap: 0.5rem;
}

.grid-gap-customv1 {
  gap: 0.5rem;
}

.grid-custom-one-column {
  grid-template-columns: 1fr;
}

.grid-custom-two-2one-column {
  grid-template-columns: 2fr repeat(2, 1fr);
}

.grid-custom-two-one-column {
  grid-template-columns: 2fr 1fr;
}

.grid-custom-one-two-column {
  grid-template-columns: 1fr 2fr;
}

.grid-custom-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.grid-custom-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-custom-four-column {
  grid-template-columns: repeat(4, 1fr);
}

.grid-custom-five-column {
  grid-template-columns: repeat(5, 1fr);
}

p,
span,
small,
div,
label,
input,
button {
  font-size: 1rem;
  color: #6d6d6d;
  margin-bottom: 0rem;
}

a,
.link-btn-custom {
  text-decoration: none;
}

.modal-form-custom-all .card-custom {
  position: relative;
  background-color: #ffffff;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.modal-form-custom-all .card-custom .close-icon {
  color: #d70000;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  z-index: 3;
  font-size: 1.5rem;
}
.modal-form-custom-all .card-custom .content {
  padding: 1.5rem;
}/*# sourceMappingURL=Styles.css.map */