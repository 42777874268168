.responsible-table-custom {

    @include custom-bar-scrool($height: 0.2rem, $width: 0.4rem);
    height: calc(100vh - 15rem);

    table {

        &.table {

            border-bottom: 1px solid rgba(138, 138, 138, 0.3);
            border-right: 1px solid rgba(138, 138, 138, 0.3);
            border-left: 1px solid rgba(138, 138, 138, 0.3);
            margin-bottom: 0rem;

            thead {
                position: sticky;
                top: 0rem;

                tr {

                    background-color: transparent;

                    th {

                        background-color: $gray-light;
                        color: $white;
                        font-size: 1rem;
                        font-weight: 500;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
            }

            tbody, tfoot {

                tr {

                    background-color: transparent;
                    border-bottom: 0.05rem solid rgba(46, 46, 46, 0.2);


                    &:hover {

                        background-color: rgba(0, 0, 0, 0.05);
                        cursor: default;
                    }

                    td {

                        font-size: 0.8rem;
                        background-color: transparent;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        padding: 0.8rem 0.4rem;

                        &.custom-loading-data-td {

                            display: none;
                            // background-color: blue;
                        }

                        &.td-action {

                            gap: 1rem;

                            .td-link {

                                padding: 0.4rem;
                                @include custom-border-radius($radius: 0.4rem);
                                @include custom-transition();

                                .icon {

                                    font-size: 0.8rem;
                                }

                                &.edit {

                                    background-color: $white;
                                    border: 0.05rem solid $green-color;
                                    color: $green-color;

                                    &:hover {

                                        color: $white;
                                        background-color: $green-color;
                                    }
                                }

                                &.delete {

                                    background-color: $white;
                                    border: 0.05rem solid $error;
                                    color: $error;

                                    &:hover {

                                        color: $white;
                                        background-color: $error;
                                    }
                                }
                            }
                        }

                        img{

                            &.img-td{

                                width: 2rem;
                                height: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.content-or-subcontent-settings{

    .responsible-table-custom {

        height: calc(100vh - 20rem);
    }
}